.red-timer {
    color: #f00101;
    font-size: 30vh !important;
    font-weight: 700 !important; 
}

.green-timer {
    color: #4cb828;
    font-size: 30vh !important;
    font-weight: 700 !important; 
}