:root {
    --grey: #D1D1D1;
    --green: #00704A;
    --dark-grey: #707070;
}

.stepper-button {
    padding-left: 13px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 15px;
    width: 90%;
    height: 17vh;
}

.stepper-button-active-color {
    color: #000000;
}

.stepper-button-inactive-color {
    color: rgba(0, 0, 0, 0.54);
}

.active-icon {
    background-color: #00704A;
}

.inactive-icon {
    background-color: #E1E1E1;
}

/* .completed-stepper-button {
    background-color: var(--green);
    color: #ffffff;
} */

/* .not-completed-stepper-button {
    color: #000000;
} */

.stepper-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-completed-stepper-icon {
    color: var(--dark-grey);
}

.completed-stepper-icon {
    background-color: var(--green);
    color: #ffffff;
}

.MuiStepConnector-vertical {
    padding: 0 !important;
}

.MuiStepper-root {
    padding-right: 0 !important;
}

.line-top {
    top: -70px;
    left: 32px;
    height: 100px;
}

.completed-line {
    position: relative;
    border-left: 10px solid var(--green);
}

.not-completed-line {
    position: relative;
    border-left: 10px solid #E1E1E1;
}

.last-step-text {
    padding: 0 20px;
}

.step-line {
    top: -70px;
    left: 20px;
    height: 100px;
}

.step-icon {
    height: 28px;
    width: 28px;
    margin-top: 50px;
    margin-right: 10px;
    z-index: 4;
}

/* .last-step-icon {
    margin-left: 18px;
} */

.green-text {
    color: var(--green);
}

.description {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 1% 2%;
    gap: 30px;
    transform-origin: center;
    z-index: 2;
    transition: width 0.5s ease-in;
    background-color: #DAECD8;
}

/* .description-not-completed {
    background-color: var(--grey);
}*/

/* .description-completed {
    background-color: var(--green);
} */

.list {
    background-color: #E8E8E8;
    border-radius: 15px;
    padding: 10px;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 25px;
}

.list-item {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    margin: 0 10px;
}

.list-btn {
    display: flex;
    justify-content: center;
}

.r1-btn {
    text-transform: none !important;
    border-radius: 10px !important;
    width: 300px !important;
}

.start-btn {
    background-color: #FD8D3D !important;
    color: #ffffff !important;
}

.pause-btn {
    color: #043F98 !important;
    border: solid 3px #043F98 !important;
}

.stop-btn {
    background-color: #ffffff !important;
    color: #FF0000 !important;
    border: solid 3px #FF0000 !important;
}

.skip-btn {
    background-color: #ffffff !important;
    color: #043F98 !important;
    border: solid 3px #043F98 !important;
}

.disabled-r1 {
    opacity: 0.5 !important;
}

.expanded-item {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 10px;
}

.weight-btn {
    width: 20% !important;
    text-transform: none !important;
    border-radius: 10px !important;
    background-color: var(--dark-grey) !important;
    color: #ffffff !important;
    font-size: 25px !important;
    height: 20% !important;
    padding: 0 !important;
}

.weight-btn-prep {
    width: "100% !important";
    text-transform: none !important;
    border-radius: 10px !important;
    background-color: var(--dark-grey) !important;
    color: #ffffff !important;
    font-size: 25px !important;
    padding: 2% 10% !important;
}

.process-info-label {
    box-sizing: border-box;
    padding: 0 3% 2.5%;
}

.completed-color {
    color: #ffffff;
}

.instruction {
    display: flex;
    flex-direction: column;
    padding: 5%;
    height: 100%;
    overflow-y: scroll;
    width: 100%;
    gap: 20px;
}

.timer {
    display: flex;
    justify-content: center;
}

.white-box {
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2% 0;
}

.max-height {
    height: 100%;
}

.instruction-accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.center-align {
    align-items: center;
}

.left-align {
    align-items: "start";
}

.prep-close {
    position: relative;
    right: 20;
    width: 98%;
}

.prep-content-parent {
    box-sizing: border-box;
    margin: 32px 34px;
    display: flex;
    /* width: 100%;
    height: 100%; */
}

.prep-content-title {
    display: flex;
    width: 40%;
    padding: 0 20px;
    gap: 20px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    background-color: #d9d9d9;
}

.prep-content-child {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.prep-title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 1% 0;
}

.label-full-view {
    width: 50%;
    transition: width 0.5s ease-in;
}

.label-reduced-view {
    width: 25%;
    transition: width 0.5s ease-in;
}

.instruction-reduced-view {
    width: 75%;
}

.active-btn {
    background-color: #DAECD8;
    z-index: -1;
    height: 132px;
    width: 50%;
    left: 5.5%;
    top: 32.5%;
    border-radius: 10px;
    position: absolute;
    transition: width 0.5s ease-in;
}

.decrease-active-btn-width {
    width: 25%;
}

.prep-ing-btn {
    border: #043F98 solid 3px !important;
    border-radius: 10px !important;
    background-color: #ffffff !important;
    color: #043F98 !important;
    text-transform: none !important;
    width: 30% !important;
}

.weight-btn-prep-modal {
    width: 100% !important;
}

.in-progress-color {
    background-color: #FFE8D8;
}

.dialog-btn-dimension {
    height: 80px;
    width: 248px;
    padding: 0;
}

.dialog-weigh-btn-dimension {
    height: 66px !important;
    width: 154px !important;
}

.prep-text-content {
    display: flex;
    flex-direction: column;
    width: 395px;
    justify-content: space-around;
    align-items: center;
}

.non-dialog-resume-stop-dimension {
    width: 225px !important;
    height: 66px !important;
}